@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+Mono:400,500,600,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Nova+Square:400,500,600,700,800,900");

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
	@apply h-screen overflow-y-auto bg-white text-stone-900;
}

* {
	box-sizing: border-box !important;
}

.input-text {
	@apply py-2 px-3 border rounded appearance-none bg-white;
}

a {
	@apply text-blue-500 hover:text-blue-600   underline cursor-pointer;
}

.heading {
	@apply text-neutral-800  font-bold font-sans mt-3 mb-4;
}

h1 {
	@apply heading text-2xl;
}

h2 {
	@apply heading text-xl;
}
h3 {
	@apply heading text-lg;
}
h4 {
	@apply heading;
}
h5 {
	@apply heading;
}

.context-menu-item {
	@apply flex gap-2 items-center justify-start;
	svg {
		width: 16px !important;
		height: 16px !important;
	}
}

svg {
	display: inline-block;
}

trix-editor {
	ul {
		li {
			margin-left: 10px;
			list-style-type: square;
		}
	}
	ol {
		li {
			margin-left: 10px;
			list-style-type: decimal;
		}
	}
	pre {
		@apply bg-stone-300 rounded p-3 text-sm;
	}
	button {
		@apply rounded border bg-blue-500 text-white font-medium py-2 px-3;
		a {
			@apply text-inherit no-underline hover:text-inherit;
		}
	}
}

trix-toolbar {
	.trix-button {
		@apply bg-stone-50;
	}
}
